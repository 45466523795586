<template>
  <div class="box-body">
    <div class="row">
      <div class="col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12"
           v-for="(equipments, type_name) in equipment_items">
        <div class="table-responsive">
          <table class="table table-hover no-border">
            <tr>
              <td>
                <div class="font-scale-2 text-bold">
                  {{ type_name.replace(/[_\s]/g, ' ') }}
                </div>
              </td>
            </tr>
            <tr v-for="equipment in equipments">
              <td>
                <div :class="car_equipments.find(eq => eq.item_id === equipment.id) ? 'text-bold' : ''"
                     style="font-size: 15px;">
                  {{ equipment.name }}
                </div>
              </td>
              <td v-if="!isDeleted">
                <label v-if="[1, 2, 4, 7, 8, 9].includes($store.getters['auth/authUser'].role.id) || carCreator.id === $store.getters['auth/authUser'].id">
                  <input :checked="car_equipments.find(eq => eq.item_id === equipment.id)"
                         class="pointer" style="transform: scale(1.4);" type="checkbox"
                         v-on:input="handleCarEquipmentItem(equipment.id, $event)">
                </label>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Form from 'vform';
import Multiselect from 'vue-multiselect';

Form.axios = API.apiClient;

export default {
  name: 'car-equipments',

  components: {
    Button, HasError, AlertError, AlertErrors,
    Multiselect,
  },

  props: {
    carCreator: {
      type: [Array, Object],
      required: true,
    },
    carId: {
      type: [String, Number],
      required: true,
    },
    isDeleted: {
      type: Boolean,
      required: true,
    },
  },

  data: () => ({
    form: new Form({
      item_id: null,
    }),

    car_equipments: [],
    car_equipments_relations: [
      'item',
    ],

    equipment_items: [],
    equipment_item: null,

    onProgress: true,
  }),

  async created() {
    try {
      const equipment_items_response = await API.apiClient.get('/cars/equipment_items');

      this.equipment_items = equipment_items_response.data;

      await this.getEquipments();
    } catch (error) {
      errorHandler(error);
    }

    this.onProgress = false;
  },

  computed: {
    validateData: function () {
      return this.equipment_item && this.form.item_id;
    },
  },

  methods: {
    async getEquipments() {
      try {
        const car_equipments_response = await API.apiClient.get(
            '/car/' + this.carId + '/equipments',
            {
              params: {
                pagination: 0,
                relations: this.car_equipments_relations,
              }
            }
        );

        this.car_equipments = car_equipments_response.data;
      } catch (error) {
        errorHandler(error);
      }

      this.onProgress = false;
    },

    handleCarEquipmentItem(equipmentItemID, event) {
        event.target.checked ?
            this.addCarEquipmentItem(equipmentItemID) :
            this.removeCarEquipmentItem(equipmentItemID);
    },
    async addCarEquipmentItem(equipmentItemID) {
      try {
        this.onProgress = true;

        this.form.item_id = equipmentItemID;

        await this.form.post('/car/' + this.carId + '/equipments/add').then(() => {
          showSuccess();
          this.getEquipments();
        }).catch((error) => {
          errorHandler(error);
        });
      } catch (error) {
        errorHandler(error);
      }

      this.form.item_id = null;

      this.onProgress = false;
    },
    removeCarEquipmentItem(equipmentItemID) {
      this.onProgress = true;

      API.apiClient.delete('/car/' + this.carId + '/equipments', {
        data: {
          item_id: equipmentItemID,
        }
      }).then(() => {
        this.getEquipments();
        showSuccess();
      }).catch((error) => {
        errorHandler(error);
      });

      this.onProgress = false;
    },
  },
}
</script>

<style scoped>
tr > td {
  padding: 0;
}
</style>
